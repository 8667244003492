import { getKLines } from '@/binance';
import MaTable from '@/components/ma-table';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { sma } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot from '@/components/charts/crypto-plot';

export default function Xxx100WeekMaChartTemplate({
  location,
  articleId,
  coinAbbreviation,
  coinFullName,
  token,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [ma100, setMa100] = useState<number[]>();
  const [ma100Line, setMa100Line] = useState<ChartLine>({
    y: [],
    color: 'blue',
    title: 'MA100',
  });

  useEffect(() => {
    getKLines(token, '1w', 1000).then(function (df: DataFrame) {
      setDataFrame(df);
      const sma100 = sma(df.close, 100);
      setMa100(sma100);

      setMa100Line({
        color: 'blue',
        title: 'MA100',
        y: sma100,
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLowerCase(), 'ma100', 'chart', 'weekly']}
      articleId={articleId}
    >
      <section>
        <CryptoPlot
          data={dataFrame}
          lines={[ma100Line]}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
        />
        <p>Last 100 Weeks of Simple Moving Average for {coinFullName}:</p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={'100-Week MA (' + coinAbbreviation + ')'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ma100}
          numberOfRows={100}
        />
      </section>
    </PostTemplate>
  );
}
