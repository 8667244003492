import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx100WeekMaChartTemplate from '@/templates/crypto/xxx-100-week-moving-average-chart';

export default function Bitcoin100WeekMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx100WeekMaChartTemplate
      location={location}
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      articleId={ArticleList.BITCOIN_100_WEEK_MOVING_AVERAGE}
    />
  );
}
